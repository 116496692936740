interface DashboardConfig {
  // backend url
  url: string;
  // engine url
  engine: string;
  // domain url
  domain: string;
}

type DashboardEnvironment = "Staging" | "Local" | "Production" | "ProductionJumpbox";

const config: {
  Staging: DashboardConfig;
  Production: DashboardConfig;
  Local: DashboardConfig;
  ProductionJumpbox:DashboardConfig;
} = {
  Local: {
    url: "http://localhost:10000",
    engine: "http://localhost:8000",
    domain: "http://localhost:3000",
  },
  Staging: {
    url: "https://botdashboard-api.myassesshub.com",
    engine: "https://botengine-api.myassesshub.com",
    domain: "https://botdashboard.myassesshub.com",
  },
  Production: {
    url: "https://botdashboard-api.assesshub.com",
    engine: "https://botengine-api.assesshub.com",
    domain: "https://botdashboard.assesshub.com",
  },
  ProductionJumpbox: {
    url: "https://botdashboard-api.assesshubdirect.com",
    engine: "https://botengine-api.assesshubdirect.com",
    domain: "https://botdashboard.assesshubdirect.com",
  }
};


function getEnvironment(): DashboardEnvironment | any {
  const hostname = window.location.hostname

  if (hostname.includes('.assesshub.com')) {
    return "Production"
  } else if (hostname.includes('.myassesshub.com')) {
    return "Staging"
  } else if (hostname.includes('.assesshubdirect.com')){
    return "ProductionJumpbox"
  } else {
    return "Local"
  }
}


const environment: DashboardEnvironment = getEnvironment();

export default config[environment];
